/* eslint-disable no-use-before-define */
import classNames from 'classnames';
import _debounce from 'lodash/debounce';
import type { ReactNode, CSSProperties } from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  Fragment,
} from 'react';
import type {
  TableOptions,
  UseResizeColumnsOptions,
  UseGlobalFiltersOptions,
  UseSortByOptions,
  UseExpandedOptions,
  UsePaginationOptions,
  UseTableInstanceProps,
  UseGlobalFiltersInstanceProps,
  UseSortByInstanceProps,
  UseExpandedInstanceProps,
  UsePaginationInstanceProps,
  TableState,
  UseResizeColumnsState,
  UseGlobalFiltersState,
  UseSortByState,
  UseExpandedState,
  UsePaginationState,
  IdType,
  ColumnInstance,
  UseFiltersColumnProps,
  UseResizeColumnsColumnProps,
  UseSortByColumnProps,
  CellPropGetter,
  TableCellProps,
  RowPropGetter,
  TableRowProps,
  TableExpandedToggleProps,
  HeaderGroupPropGetter,
  TableHeaderProps,
  FooterGroupPropGetter,
  TableFooterProps,
  Renderer,
  Accessor,
  CellProps,
  ActionType,
} from 'react-table';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import { Loader } from 'semantic-ui-react';

import { sortAlphanumeric } from '@/DOMUtils';
import TablePagination from '@shared/components/Table/TablePaginationV7';
import ExpirationDate from '@shared/components/formatters/ExpirationDate';
import TextFormatter from '@shared/components/formatters/Text';

type NoDataPropsType =
  | (object & {
      CustomComponent?: React.FC<any>;
    })
  | undefined;

type ReactTableOptions<T extends object> = TableOptions<T> &
  UseResizeColumnsOptions<T> &
  UseGlobalFiltersOptions<T> &
  UseSortByOptions<T> &
  UseExpandedOptions<T> &
  UsePaginationOptions<T> & {
    SubComponent?: (row: ReactTableRow<T>) => JSX.Element;
  };

type ReactTableState<T extends object> = TableState<T> &
  UseResizeColumnsState<T> & {
    columnResizing: {
      columnWidths: Record<string, number>;
      previousColumnWidths: Record<string, number>;
    };
  } & UseGlobalFiltersState<T> &
  UseSortByState<T> &
  UseExpandedState<T> &
  UsePaginationState<T>;

export type ReactTableExtendedState<T extends object> = ReactTableState<T> & {
  page: number;
  pages: number;
  sortedData: Array<
    Record<IdType<T>, any> & {
      _original: T;
    }
  >;
  data: Array<T>;
  filtered: any;
  filterStr: string;
};

export interface ReactTableInstance<T extends object>
  extends Omit<ReactTableOptions<T>, 'columns' | 'pageCount'>,
    UseTableInstanceProps<T>,
    UseGlobalFiltersInstanceProps<T>,
    UseSortByInstanceProps<T>,
    UseExpandedInstanceProps<T>,
    UsePaginationInstanceProps<T> {
  page: ReactTableRow<T>[];
  rows: ReactTableRow<T>[];
  rowsById: Record<string, ReactTableRow<T>>;
  headerGroups: Array<ReactTableHeaderGroup<T>>;
  footerGroups: Array<ReactTableHeaderGroup<T>>;
}

export type ReactTableExtendedInstance<T extends object> = {
  state: ReactTableExtendedState<T>;
};

type ReactTableColumnInstance<T extends object> = ColumnInstance<T> &
  UseFiltersColumnProps<T> &
  UseResizeColumnsColumnProps<T> &
  UseSortByColumnProps<T> &
  ReactTableExtendedColumnInterface<T>;

type ReactTableHeaderGroup<D extends object = any> =
  ReactTableColumnInstance<D> & {
    headers: Array<ReactTableHeaderGroup<D>>;
    getHeaderGroupProps: (
      propGetter?: HeaderGroupPropGetter<D>,
    ) => TableHeaderProps;
    getFooterGroupProps: (
      propGetter?: FooterGroupPropGetter<D>,
    ) => TableFooterProps;
    totalHeaderCount: number; // not documented
  };

type ReactTableCell<T extends object> = {
  column: ReactTableColumnInstance<T>;
  row: ReactTableRow<T>;
  value: any;
  getCellProps: (propGetter?: CellPropGetter<T>) => TableCellProps;
  render: (type: 'Cell' | string, userProps?: object) => ReactNode;
};

type ReactTableRow<T extends object> = {
  value: any;
  cells: Array<ReactTableCell<T>>;
  allCells: Array<ReactTableCell<T>>;
  values: Record<IdType<T>, any>;
  getRowProps: (propGetter?: RowPropGetter<T>) => TableRowProps;
  index: number;
  original: T;
  id: string;
  subRows: Array<ReactTableRow<T>>;
  isExpanded: boolean;
  canExpand: boolean;
  toggleRowExpanded: (value?: boolean) => void;
  getToggleRowExpandedProps: (
    props?: Partial<TableExpandedToggleProps>,
  ) => TableExpandedToggleProps;
  depth: number;
};

type ReactTableExtendedColumnInterface<T extends object> = {
  resizable?: boolean;
  headerClassName?: string;
  className?: string;
  fixedWidth?: number;
  show?: boolean;
  sortMethod?: (a: any, b: any) => number;
  style?: CSSProperties;
  sortable?: boolean;
  headerStyle?: CSSProperties;
  cellType?: 'text' | 'expirationDate';
  formatterProps?: any;
  disabled?: boolean;
  editable?: boolean;
  onSaveEditor?: (
    original: T,
    column: ReactTableColumnInstance<T>,
    value: any,
  ) => void;

  // Deprecated: included for backwards-compatibility only
  filterMethod?: (conf: { value: any }, rowValues: any) => any;
};

type HeaderProps<D extends object> = ReactTableInstance<D> & {
  column: ReactTableColumnInstance<D>;
};

type FooterProps<D extends object> = ReactTableInstance<D> & {
  column: ReactTableColumnInstance<D>;
};

export type ReactTableColumnProps<T extends object> = {
  id?: IdType<T>;
  accessor?: IdType<T> | Accessor<T>;
  Header?: Renderer<HeaderProps<T>>;
  Footer?: Renderer<FooterProps<T>>;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  Cell?: Renderer<
    ReactTableRow<T> & { row: Record<IdType<T>, any>; value: any }
  >;
} & ReactTableExtendedColumnInterface<T>;

export interface ReactTableProps<
  T extends object,
  NoDataProps extends NoDataPropsType,
> {
  /* General options ------------------------------------------------------- */

  // The array of rows holding the data to display in the table—can be either
  // contains an array of items (because policy data is screwy) or objects
  data: Array<T> | null;

  // Array of column definitions for the data set
  columns: Array<ReactTableColumnProps<T>>;

  // ReactTable should be wrapped around a function to be used for the actual rendering
  children: (
    state: ReactTableExtendedState<T>,
    renderTable: () => JSX.Element,
    tableOptions: ReactTableProps<T, NoDataProps>,
  ) => JSX.Element;

  // CSS class to use for the table
  className?: string;

  // Callback to get the reference to the table instance
  tableRef?: (tableInstance: ReactTableExtendedInstance<T>) => void;

  // Is the table currently loading
  // If so, we display a loading message
  loading?: boolean;

  // Allows to override the default loading widget with a user-defined component
  LoadingComponent?: (conf: { loading: boolean }) => JSX.Element;

  // Minimum number of rows to display
  // If there are fewer rows in the data set, the grid will be padded with empty rows
  minRows?: number;

  // Are the grid columns resizable? (True by default)
  // Each column also has an optional resizable option. By default, all columns are resizable if the table is resizable
  resizable?: boolean;

  // Component to render if there are no rows to display
  NoDataComponent?: (tableProps: NoDataProps) => JSX.Element;
  // Provide properties to the NoDataComponent
  getNoDataProps?: () => NoDataProps;

  // Provide additional properties to use at the TR row level
  getTrProps?: (state: ReactTableState<T>, row: any) => any;
  // Provide additional properties to use at the TD cell level
  getTdProps?: (state: ReactTableState<T>, cell: any) => any;

  // getExtraRowProps can be used to augment the row object with addition properties
  // This function is called before calling the column.Cell to provide user-defined
  // values to the row property
  getExtraRowProps?: () => any;

  // Component to use for displaying the column headers
  TheadComponent?: () => JSX.Element;

  // Properties to pass in to the TheadComponent
  getTheadProps?: () => any;

  /* Row expansion options ------------------------------------------------- */

  // SubComponent to display when a row is expanded
  // It should be a function receiving the row object as parameter and returning a React element
  SubComponent?: (row: ReactTableRow<T>) => JSX.Element;

  // Reducer function to react to changes to the expanded property
  // This function will be called any time the list of expanded rows changes, and
  // it should return the new expanded object (of the form { rowId: boolean })
  expandedReducer?: (previousExpanded: any, newExpanded: any) => any;

  columnWidths?: Record<string, number>;
  setColumnWidths?: (newWidths: Record<string, number>) => void;

  // Should we reset column widths when the columns array changes
  // Defaults to false
  resetColumnWidths?: boolean;

  // Hide the expander column that is normally added as a first column when there is a SubComponent
  hideExpanderColumn?: boolean;

  // By default, expanded rows collapse if the data changes. Set to false to override this behavior
  // NB: Set this to false when using in a React class component, because without memoizing the data array,
  // expanded rows will be collapsed on every component render
  collapseOnDataChange?: boolean;

  // Set to true to reset sorting to default when the data changes (default false).
  // NB: Set this to false when using in a React class component, because without memoizing the data array,
  // sorting will reset on every component render
  resetSortingOnDataChange?: boolean;

  /* Pagination options ---------------------------------------------------- */
  showPagination?: boolean;
  page?: number;
  pageSize?: number;
  getPaginationProps?: () => any;
  onPageChange?: (newPageNumber: number) => void;
  onPageSizeChange?: (newPageSize: number) => any;
  manualPagination?: boolean;
  pageCount?: number;

  /* Filtering options ----------------------------------------------------- */
  filterEnabled?: boolean;
  serverFilterEnabled?: boolean;

  // filtered is provided for backwards compatibility only
  // For new implementations, use filterStr
  filtered?: Array<{
    id: string;
    value: any;
  }>;

  // The filter string used to filter the table
  filterStr?: string;

  // Function for user-defined filtering mechanism
  filterMethod?: (conf: { value: any }, rowValues: any) => any;

  // Callback function invoked any time the filter value changes
  onFilteredChange?: (col: any, value: any) => any;

  /* Sorting options ------------------------------------------------------- */

  // Are the grid columns sortable? (True by default)
  // Each column also has an optional sortable option. By default, all columns are sortable if the table is sortable
  sortable?: boolean;

  // Callback function invoked any time the filter value changes
  onSortedChange?: (
    sortedBy: Array<{ id: string; desc?: boolean }>,
    tableInstance: ReactTableInstance<T>,
  ) => any;

  // Default sorting to use for table
  defaultSorted?: Array<{
    id: string;
    desc?: boolean;
  }>;
  manualSorting?: boolean;
  multiSort?: boolean;

  // MinWidth setting to use for the table header
  // This property can be used to constrain the size of the table, e.g. for printing
  minHeaderWidth?: string;
}

const ReactTableNoData = <NoDataProps extends NoDataPropsType>(
  props: NoDataProps,
) => {
  const CustomComponent = props?.CustomComponent;
  if (CustomComponent) {
    return <CustomComponent {...props} />;
  }
  return <div>No Data</div>;
};

interface ReactTableCellProps<T extends object | Array<any>> {
  column: ReactTableColumnProps<T>;
  cellInfo: CellProps<T>;
  getExtraRowProps?: (row: ReactTableRow<T>) => any;
  openCellEditor: string;
  setOpenCellEditor: (val: string) => void;
}

const TableCell = <T extends object | Array<any>>({
  column,
  cellInfo,
  getExtraRowProps = undefined,
  openCellEditor,
  setOpenCellEditor,
}: ReactTableCellProps<T>) => {
  let cellContents = cellInfo.value || '';
  if (column.Cell) {
    const row = {
      ...(cellInfo.row as ReactTableRow<T>),
      row: cellInfo.row.values,
      value: cellInfo.value,
    };
    const extendedRow = {
      ...row,
      ...(getExtraRowProps ? getExtraRowProps(row) : {}),
    };
    cellContents = (column.Cell as any)(extendedRow, cellInfo);
  }

  let CellFormatter;
  let formatterProps = {};
  if (column.cellType) {
    const rowAndColId = `${cellInfo.row.id}_${cellInfo.column.id}`;
    switch (column.cellType) {
      case 'text':
        CellFormatter = TextFormatter;
        formatterProps = {
          ...column.formatterProps,
          editable: column.editable,
          disabled: column.disabled,
          editor: {
            ...column.formatterProps?.editor,
            cell: cellInfo.cell,
            rowId: cellInfo.row.id,
            onClick: () => {
              if (openCellEditor !== rowAndColId) {
                setOpenCellEditor(rowAndColId);
              }
            },
            open: openCellEditor === rowAndColId,
            closeEditor: () => setOpenCellEditor(''),
            updateValue: value =>
              column.onSaveEditor &&
              column.onSaveEditor(
                cellInfo.row.original,
                cellInfo.column as ReactTableColumnInstance<T>,
                value,
              ),
          },
        };
        break;

      case 'expirationDate':
        CellFormatter = ExpirationDate;
        formatterProps = {
          ...column.formatterProps,
          value: cellContents,
          enabled: !column.disabled,
          editable: column.editable,
          onSetExpirationDate: value =>
            column.onSaveEditor &&
            column.onSaveEditor(
              cellInfo.row.original,
              cellInfo.column as ReactTableColumnInstance<T>,
              value,
            ),
        };
        break;

      default:
        CellFormatter = null;
    }
  }

  if (CellFormatter) {
    return <CellFormatter {...formatterProps}>{cellContents}</CellFormatter>;
  }
  return <>{cellContents}</>;
};

const ReactTable = <
  NoDataPropType extends NoDataPropsType,
  T extends object | Array<any>,
>(
  props: ReactTableProps<T, NoDataPropType>,
) => {
  const {
    LoadingComponent,
    NoDataComponent,
    SubComponent,
    TheadComponent,
    className = '',
    collapseOnDataChange = true,
    resetSortingOnDataChange = false,
    columns,
    data,
    defaultSorted,
    getExtraRowProps,
    getNoDataProps,
    getPaginationProps,
    getTdProps = () => ({}),
    getTheadProps = () => ({}),
    getTrProps = () => ({}),
    hideExpanderColumn,
    loading = false,
    manualPagination = false,
    manualSorting = false,
    minRows = 0,
    multiSort = true,
    onSortedChange,
    page: pageNumber = 0,
    pageCount: manualPageCount,
    pageSize,
    resizable: tableResizable = true,
    serverFilterEnabled = false,
    showPagination,
    sortable: tableSortable = true,
    columnWidths,
    setColumnWidths,
    resetColumnWidths = false,
    minHeaderWidth,
  } = props;

  const shouldSortTable = tableSortable ?? true;
  const tableRef = useRef<HTMLDivElement>(null);
  const [previousSortBy, setPreviousSortBy] = useState(defaultSorted || []);

  const paginationProps = useMemo(
    () => (getPaginationProps ? getPaginationProps() : {}),
    [getPaginationProps],
  );
  const filterEnabled = props.filterEnabled || !paginationProps.noFilter;
  const filterStr =
    props.filterStr || (props.filtered?.length && props.filtered[0]?.value);
  const filtered = [{ id: 'filter', value: filterStr }];
  const filterColumn = useMemo(
    () => columns.find(col => col.id === 'filter'),
    [columns],
  );
  const filterMethod = useMemo(
    () => props.filterMethod || filterColumn?.filterMethod,
    [props.filterMethod, filterColumn],
  );

  /* Defining default column width for every column */
  const defaultColumn = {
    minWidth: 30,
    width: 50,
    maxWidth: 400,
  };

  const expanderColumn = useMemo(() => {
    /* If the table is expandable then add the expander column */
    if (SubComponent && !hideExpanderColumn) {
      return {
        Header: '',
        id: 'expander',
        resizable: false,
        className: 'text-center',
        fixedWidth: 3,
        Cell: row => (
          // Applying the toggle expander props i.e onClick, style and title
          <div
            {...row.getToggleRowExpandedProps()}
            title="Click here to see more information"
            className="rt-td rt-expandable p-0"
            style={{ padding: '0' }}
          >
            <div
              className={classNames(
                'rt-expander',
                row.isExpanded ? '-open' : '',
              )}
            >
              •
            </div>
          </div>
        ),
      } as ReactTableColumnProps<T>;
    }
    return undefined;
  }, [SubComponent, hideExpanderColumn]);

  const sortType = useCallback(
    sortMethod => (a, b, colId) =>
      sortMethod
        ? sortMethod(a.values[colId], b.values[colId])
        : sortAlphanumeric(a.values[colId], b.values[colId]),
    [],
  );

  const [openCellEditorRowAndColId, setOpenCellEditorRowAndColId] =
    useState('');

  const columnsV7 = useMemo(() => {
    const cols = expanderColumn ? [expanderColumn, ...columns] : columns;
    return cols
      .map((col, index) => ({
        ...col,
        _index: index,
        accessor: col.accessor as any, // Typing for property accessor is extremely complicated...
        Cell: colInfo => (
          <TableCell
            key={`tablecell_${col.id}`}
            column={col}
            cellInfo={colInfo}
            openCellEditor={openCellEditorRowAndColId}
            setOpenCellEditor={setOpenCellEditorRowAndColId}
            getExtraRowProps={getExtraRowProps}
          />
        ),
        sortType: sortType(col.sortMethod),
      }))
      .filter(col => col.id !== 'filter');
  }, [columns, expanderColumn, getExtraRowProps, openCellEditorRowAndColId]);

  const hiddenColumns = useMemo(
    () =>
      columnsV7.filter(col => !(col.show ?? true)).map(col => String(col.id)),
    [columnsV7],
  );

  const visibleColumns = useMemo(
    () => columnsV7.filter(col => col.show ?? true),
    [columnsV7],
  );

  const fixedColumns = useMemo(
    () => columnsV7.filter(col => !!col.fixedWidth),
    [columnsV7],
  );

  const remToPx = rem =>
    rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

  const globalFilter = useCallback(
    (unfilteredRows, columnIds, filterValue) => {
      if (filterEnabled && filterMethod && !serverFilterEnabled) {
        const rowValues = unfilteredRows.map((row, index) => ({
          reactTableRowIndex: index,
          ...row.values,
        }));
        const filteredRowValues = filterMethod(
          { value: filterValue },
          rowValues,
        );
        return filteredRowValues
          .map(row => ({
            ...unfilteredRows[row.reactTableRowIndex],
            values: row,
          }))
          .sort((a, b) => a.index - b.index);
      }
      return unfilteredRows;
    },
    [filterEnabled, filterMethod, serverFilterEnabled],
  );

  const expandedReducer = useCallback(
    (previousExpanded, newExpanded) => {
      if (props.expandedReducer) {
        return props.expandedReducer(previousExpanded, newExpanded);
      }
      return newExpanded;
    },
    [props.expandedReducer],
  );

  const getOriginalColumnWidth = useCallback(
    col => {
      const origColumn = columnsV7.find(c => c.id === col.id);
      if (!origColumn) throw new Error(`Column ${col.id} not found`);
      let width = origColumn.width || defaultColumn.width;
      width = typeof width === 'string' ? parseInt(width, 10) : width;
      if (origColumn.maxWidth)
        width = Math.min(width, origColumn.maxWidth || defaultColumn.maxWidth);
      if (origColumn.minWidth)
        width = Math.max(width, origColumn.minWidth || defaultColumn.minWidth);
      return width;
    },
    [columnsV7, defaultColumn],
  );

  const initialColumnWidths = useMemo(
    () =>
      columnsV7.reduce(
        (widths, col) => {
          widths[String(col.id)] = getOriginalColumnWidth(col);
          return widths;
        },
        {} as Record<string, number>,
      ),
    [],
  );

  useEffect(() => {
    if (setColumnWidths && !columnWidths) {
      setColumnWidths(initialColumnWidths);
    }
  }, [initialColumnWidths]);

  const calcWidthIncrement = useCallback((col, width, increment) => {
    let newWidth = width + increment;
    newWidth = Math.min(newWidth, col.maxWidth ?? defaultColumn.maxWidth);
    newWidth = Math.max(newWidth, col.minWidth ?? defaultColumn.minWidth);
    return newWidth - width;
  }, []);

  const getWidthsForColumns = useCallback(
    (columnToResize, newColWidth, cols, colWidths, widthDiff) => {
      const defaultIncrement =
        (widthDiff > 0 ? -1 : 1) *
        (Math.floor(Math.abs(widthDiff / cols.length)) || 1);
      const filteredColWidths = cols.reduce(
        (acc, col) => ({
          ...acc,
          [col.id]: colWidths[col.id] || initialColumnWidths[col.id],
        }),
        {},
      );
      const newColWidths = cols.reduce(
        (acc, col) => {
          if (Math.abs(acc.remaining) > 0) {
            const widthToAdd = calcWidthIncrement(
              col,
              acc.widths[col.id],
              defaultIncrement,
            );
            acc.widths[col.id] += widthToAdd;
            acc.remaining += widthToAdd;
            acc.totalIncremented += widthToAdd;
          }
          return acc;
        },
        {
          widths: filteredColWidths,
          totalIncremented: 0,
          remaining: Math.floor(widthDiff),
        },
      );
      if (newColWidths.remaining === 0 || newColWidths.totalIncremented === 0) {
        return {
          [columnToResize.id]: newColWidth - newColWidths.remaining,
          ...newColWidths.widths,
        };
      }
      return getWidthsForColumns(
        columnToResize,
        newColWidth,
        cols,
        newColWidths.widths,
        newColWidths.remaining,
      );
    },
    [],
  );

  const stateReducer = useCallback(
    (
      state: TableState<T>,
      action: ActionType,
      previousState: TableState<T>,
    ) => {
      const newState = state as ReactTableState<T>;
      const prevState = previousState as ReactTableState<T>;
      switch (action.type) {
        case 'resetResize': {
          if (!resetColumnWidths) {
            return prevState;
          }
          break;
        }

        case 'toggleRowExpanded': {
          return {
            ...newState,
            expanded: expandedReducer(prevState.expanded, newState.expanded),
          };
        }

        case 'columnStartResizing': {
          return {
            ...newState,
            columnResizing: {
              ...newState.columnResizing,
              previousColumnWidths: newState.columnResizing.columnWidths,
            },
          };
        }

        case 'columnDoneResizing': {
          if (setColumnWidths) {
            setTimeout(
              () => setColumnWidths(newState.columnResizing.columnWidths),
              0,
            );
          }
          return {
            ...newState,
            columnResizing: {
              ...newState.columnResizing,
              previousColumnWidths: null,
            },
          };
        }

        case 'columnResizing': {
          const deltaX = action.clientX - (newState.columnResizing.startX ?? 0);
          const { isResizingColumn } = newState.columnResizing;
          const columnId =
            isResizingColumn || prevState.columnResizing.isResizingColumn;
          const origColumn = columnsV7.find(col => col.id === columnId);

          if (origColumn && deltaX !== 0) {
            const colsToRight = visibleColumns.filter(
              col =>
                (col.resizable ?? true) &&
                !col.fixedWidth &&
                // eslint-disable-next-line no-underscore-dangle
                col._index > origColumn._index,
            );
            const flexColumnsWidth = visibleColumns
              .filter(col => !col.fixedWidth)
              .reduce(
                (sum, col) =>
                  sum +
                  (newState.columnResizing.previousColumnWidths[col.id!] ||
                    initialColumnWidths[String(col.id)]),
                0,
              );
            const fixedColsWidth = fixedColumns.reduce(
              // col.fixedWidth is expressed in rem units, so we need to convert it to pixels
              (sum, col) => remToPx(col.fixedWidth) + sum,
              0,
            );
            // We need to subtract the width of fixed columns, because they aren't taken into account
            // by the flexbox calculations, because they are set to flex: `0 0 ${flexWidth}em`
            const tableWidth =
              (tableRef.current?.offsetWidth || 0) - fixedColsWidth;
            const colWidth = newState.columnResizing.columnWidth;
            const widthIncrement = calcWidthIncrement(
              origColumn,
              colWidth,
              Math.floor((deltaX / tableWidth) * flexColumnsWidth),
            );

            const newColWidths = {
              ...prevState.columnResizing.columnWidths,
              ...getWidthsForColumns(
                origColumn,
                colWidth + widthIncrement,
                colsToRight,
                newState.columnResizing.previousColumnWidths,
                widthIncrement,
              ),
            };

            return {
              ...newState,
              columnResizing: {
                ...newState.columnResizing,
                columnWidths: newColWidths,
              },
            };
          }
          return newState;
        }

        default: {
          return newState;
        }
      }
      return newState;
    },
    [columnsV7],
  );

  const useTableOptions: ReactTableOptions<T> = {
    columns: columnsV7,
    data: data || [],
    defaultColumn,
    SubComponent,
    initialState: {
      globalFilter: filterStr,
      pageSize,
      pageIndex: pageNumber,
      sortBy: defaultSorted || [],
      hiddenColumns,
      columnResizing: {
        columnWidths: columnWidths || initialColumnWidths,
      },
    } as ReactTableState<T>,
    globalFilter,
    stateReducer,
    manualSortBy: manualSorting,
    ...(manualPagination
      ? {
          manualPagination,
          pageCount: manualPageCount,
        }
      : {}),
    disableMultiSort: !multiSort,
    disableSortRemove: true,
    disableSortBy: !shouldSortTable,
    autoResetExpanded: collapseOnDataChange ?? true,
    autoResetSortBy: resetSortingOnDataChange ?? false,
  };
  const tableInstance = useTable(
    useTableOptions,
    useFlexLayout,
    useResizeColumns,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  ) as ReactTableInstance<T>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    rowsById,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    state,
    setGlobalFilter,
    setHiddenColumns,
  } = tableInstance as ReactTableInstance<T>;
  const tableState = state as ReactTableState<T>;

  const unsortedRows = useMemo(
    () => Object.keys(rowsById).map(key => rowsById[key]),
    [rowsById],
  );

  useEffect(() => {
    if (hiddenColumns.join() !== state?.hiddenColumns?.join())
      setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if (manualPagination && pageNumber !== tableState.pageIndex)
      gotoPage(pageNumber);
  }, [manualPagination, pageNumber, gotoPage, tableState.pageIndex]);

  useEffect(() => {
    const sortCols = sortDefs =>
      sortDefs?.map(def => `${def.id}${def.desc ?? true}`)?.join();
    if (sortCols(tableState.sortBy) !== sortCols(previousSortBy)) {
      if (onSortedChange) onSortedChange(tableState.sortBy, tableInstance);
      setPreviousSortBy(tableState.sortBy);
    }
  }, [previousSortBy, setPreviousSortBy, tableState.sortBy, onSortedChange]);

  const onPageChange = useMemo(
    () => (value: number) => {
      if (tableState.pageIndex !== value) {
        gotoPage(value);
        if (props.onPageChange) props.onPageChange(value);
      }
    },
    [tableState.pageIndex, gotoPage, props.onPageChange],
  );

  const [currentFilter, setCurrentFilter] = useState(filterStr);
  const updateFilter = useCallback(
    _debounce((column, value) => {
      if (value !== tableState.globalFilter) {
        if (props.onFilteredChange) props.onFilteredChange(column, value);
        setGlobalFilter(value);
        if (tableState.pageIndex > 0) onPageChange(0);
      }
    }, 400),
    [tableState.globalFilter, props.onFilteredChange, tableState.pageIndex],
  );

  const onFilteredChange = useCallback(
    (column, value) => {
      if (value !== currentFilter) {
        setCurrentFilter(value);
        updateFilter(column, value);
      }
    },
    [currentFilter, updateFilter],
  );

  const onPageSizeChange = useMemo(
    () => (value: number) => {
      if (props.onPageSizeChange) props.onPageSizeChange(value);
      setPageSize(value);
    },
    [props.onPageSizeChange, setPageSize],
  );

  const visibleRows = showPagination
    ? page
    : shouldSortTable
      ? rows
      : unsortedRows;
  const rowData = useMemo(
    () => rows.map(row => ({ ...row.values, _original: row.original })),
    [rows],
  );
  const extendedState = useMemo(
    () => ({
      ...tableState,
      page: tableState.pageIndex,
      pages: pageCount,
      sortedData: rowData,
      data: data || [],
      filtered,
      filterStr,
    }),
    [tableState, pageCount, rowData, filterStr, filtered, data],
  );

  useEffect(() => {
    if (props.tableRef) {
      props.tableRef({
        ...tableInstance,
        state: extendedState,
      });
    }
  }, [props.tableRef, tableInstance]);

  const HeaderRow =
    TheadComponent ??
    (({ children, className: headerClassName, minWidth, ...rest }) => (
      <div
        className={classNames('rt-thead', '-header', headerClassName)}
        {...{ rest, style: { ...rest.style, minWidth } }}
      >
        {children}
      </div>
    ));

  const getFixedColumnStyle = col =>
    col.fixedWidth
      ? {
          minWidth: `${col.fixedWidth}rem`,
          width: `${col.fixedWidth}rem`,
          maxWidth: `${col.fixedWidth}rem`,
          flex: `0 0 ${col.fixedWidth}rem`,
        }
      : {};

  const getColHeaderStyle = col => ({
    ...getFixedColumnStyle(col),
  });

  const getCellStyle = cell => ({
    ...getFixedColumnStyle(cell.column),
  });

  const table = (
    <div className={classNames(className, 'ReactTable')} ref={tableRef}>
      {showPagination ? (
        <div className="pagination-top">
          <TablePagination
            {...paginationProps}
            state={extendedState}
            page={tableState.pageIndex}
            pages={pageCount}
            pageSize={tableState.pageSize}
            rawData={rowData}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onFilteredChange={onFilteredChange}
            filterStr={currentFilter}
            noFilter={!filterEnabled}
          />
        </div>
      ) : null}
      <div {...getTableProps()} className="rt-table">
        <HeaderRow {...getTheadProps()} minWidth={minHeaderWidth}>
          {
            // Looping over the header rows
            headerGroups.map(headerGroup => (
              // Applying the header row props
              <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {
                  // Looping over the headers in each row
                  headerGroup.headers.map(column => {
                    const resizable =
                      tableResizable &&
                      (column.resizable ?? true) &&
                      !column.fixedWidth;
                    const sortable =
                      shouldSortTable && (column.sortable ?? true);
                    const origColumn = columnsV7.find(
                      col => col.id === column.id,
                    );
                    if (!origColumn)
                      throw new Error(`Column ${column.id} not found`);
                    const colsToRight = visibleColumns.filter(
                      col =>
                        (col.resizable ?? true) &&
                        !col.fixedWidth &&
                        // eslint-disable-next-line no-underscore-dangle
                        col._index > origColumn._index,
                    );
                    const lastResizableColumn = colsToRight.length === 0;
                    return (
                      <div
                        {...column.getHeaderProps([
                          {
                            className: classNames(
                              'rt-th',
                              column.headerClassName,
                              tableResizable ? 'rt-resizable-header' : '',
                              sortable && column.isSorted
                                ? column.isSortedDesc
                                  ? '-sort-desc'
                                  : '-sort-asc'
                                : '',
                            ),
                            style: {
                              ...getColHeaderStyle(column),
                              ...column.headerStyle,
                            },
                          },
                        ])}
                      >
                        <>
                          <div
                            {...(sortable ? column.getSortByToggleProps() : {})}
                            title=""
                            className={
                              resizable
                                ? 'rt-resizable-header-content'
                                : 'rt-header-content'
                            }
                          >
                            {column.render('Header')}
                          </div>
                          {resizable && !lastResizableColumn ? (
                            <div
                              {...column.getResizerProps()}
                              className="rt-resizer"
                            />
                          ) : null}
                        </>
                      </div>
                    );
                  })
                }
              </div>
            ))
          }
        </HeaderRow>
        {visibleRows.length ? (
          <div className="rt-tbody">
            {
              // Looping over the table rows
              visibleRows.map((row, ind) => {
                // Preparing row for rendering
                prepareRow(row);
                return (
                  <Fragment key={row.id}>
                    <div {...getTableBodyProps()} className="rt-tr-group">
                      {/* Applying the row props and adding classname for alternate rows */}
                      <div
                        {...row.getRowProps()}
                        {...getTrProps(tableState, row)}
                        className={classNames(
                          'rt-tr',
                          ind % 2 === 0 ? '-odd' : '-even',
                        )}
                      >
                        {
                          // Looping over the row cells
                          row.cells.map(cell => (
                            <div
                              {...getTdProps(tableState, cell)}
                              {...cell.getCellProps({
                                className: classNames(
                                  'rt-td',
                                  cell.column.className,
                                ),
                                style: {
                                  ...getCellStyle(cell),
                                  ...cell.column.style,
                                },
                              })}
                            >
                              {cell.render('Cell')}
                            </div>
                          ))
                        }
                      </div>
                      {row.isExpanded && SubComponent
                        ? SubComponent(row)
                        : null}
                    </div>
                  </Fragment>
                );
              })
            }
            {[
              ...Array(
                Math.min(
                  Math.abs(tableState.pageSize - page.length || 0),
                  minRows,
                ),
              ).keys(),
            ].map(index => (
              <div {...getTableBodyProps()} className="rt-tr-group">
                <div
                  className={classNames(
                    'rt-tr',
                    (page.length + index) % 2 === 0 ? '-odd' : '-even',
                  )}
                />
              </div>
            ))}
          </div>
        ) : (
          <ReactTableNoData
            key="no-data-control"
            {...(getNoDataProps ? getNoDataProps() : {})}
            CustomComponent={NoDataComponent}
          />
        )}
      </div>
      {/* Loading overlay */}
      <div className={classNames('-loading', loading ? '-active' : '')}>
        {LoadingComponent ? (
          LoadingComponent({ loading })
        ) : (
          <div className="-loading-inner">
            <Loader active={loading} size="huge" />
          </div>
        )}
      </div>
    </div>
  );
  const tableOptions = {
    ...props,
    onPageChange,
    onPageSizeChange,
    onFilteredChange,
  };
  return props.children(extendedState, () => table, tableOptions);
};

export default ReactTable;
