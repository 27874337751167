const getEntityObject = (state: any, name: string) => {
  if (!name) return [state, ''];
  const path = name.split('/');
  const key = path.pop();
  if (!key) {
    throw new Error(
      'Please provide a valid "stateKey" property for the entity',
    );
  }
  let base = state;
  if (path.length) {
    path.forEach(item => {
      base = base[item];
    });
  }
  return [base, key];
};

export default getEntityObject;
