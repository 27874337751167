import { createSlice } from '@reduxjs/toolkit';

import type {
  StatusEntity,
  SystemResourceLimit,
  GetLimitsResponse,
  GetLimitsActionParams,
} from '@models';
import { systemLimitDisplayNames, nullStatusEntity } from '@models';
import raiseToast from '@shared/components/Toast';

import {
  createEntityAction,
  createStatusEntityReducers,
  getEntityActions,
} from './common';

const raiseLimitReachedToast = (
  limit: SystemResourceLimit,
  isAdmin: boolean,
  exceeded: boolean,
) => {
  raiseToast({
    toastId: `limit-exceeded-${limit.name}`,
    title: `System Limit ${exceeded ? 'Exceeded' : 'Reached'} : ${systemLimitDisplayNames[limit.name!]}`,
    message: `The system has ${exceeded ? 'exceeded' : 'reached'} the allowed limit of ${limit.limitValue} for: ${systemLimitDisplayNames[limit.name!]} (current value: ${limit.currentValue}). ${isAdmin ? 'Please check your system limits to resolve this issue.' : 'Please contact your administrator to resolve this issue.'}`,
    level: 'error',
    icon: 'times circle',
  });
};

const raiseLimitApproachingToast = (
  limit: SystemResourceLimit,
  isAdmin: boolean,
) => {
  raiseToast({
    toastId: `limit-approaching-${limit.name}`,
    title: `Approaching System Limit: ${systemLimitDisplayNames[limit.name!]}`,
    message: `The system is approaching the allowed limit of ${limit.limitValue} for: ${systemLimitDisplayNames[limit.name!]} (current value: ${limit.currentValue}). ${isAdmin ? 'Please check your system limits to resolve this issue.' : 'Please contact your administrator to resolve this issue.'}`,
    level: 'warning',
    icon: 'warning sign',
  });
};

const checkLimits = (limits: SystemResourceLimit[], isAdmin: boolean) => {
  limits
    .filter(
      item =>
        item.limitExceeded ||
        (item.warningValue &&
          item.currentValue &&
          item.currentValue > item.warningValue),
    )
    .forEach(limit =>
      limit.limitExceeded
        ? raiseLimitReachedToast(
            limit,
            isAdmin,
            (limit.currentValue ?? 0) > (limit.limitValue ?? 0),
          )
        : raiseLimitApproachingToast(limit, isAdmin),
    );
};

const entities = {
  getSystemLimits: createEntityAction<GetLimitsActionParams, GetLimitsResponse>(
    {
      stateKey: '',
      sliceName: 'limits',
      servicePath: () => `/service/limits`,
      onSuccess: (response, params, thunkApi) => {
        const { account } = thunkApi.getState().auth;
        const isAdmin = account && account.isAdmin;
        checkLimits(response.body.data, isAdmin);
      },
    },
  ),
};

export const { getSystemLimits } = getEntityActions(entities);

export type LimitsState = StatusEntity<SystemResourceLimit[]>;

export const initialState: LimitsState = nullStatusEntity;

const limitsSlice = createSlice({
  name: 'limits',
  initialState,
  reducers: {},
  extraReducers: builder => {
    createStatusEntityReducers(entities, builder);
  },
});

export default limitsSlice.reducer;
